import React, {Component} from 'react';

class Logo extends Component {
    render() {
        return (

            <a className="home-link" href={process.env.PUBLIC_URL + '/'} title="HireAT" rel="home">
                <img id="logo-img" height="50" width="170" className="img-fluid auto_size" src="images/logo.png" alt="logo-img"/>
             </a>
        );
    }
}

export default Logo;