import React, { Component } from 'react';
import Slider from 'react-slick';
import ProgressBar from 'react-animated-progress-bar';
import Header from '../components/layout/Header3';
import { Banner } from '../components/banner/Home2_banner';
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import Video from '../components/layout/Video';


const sliderElements = [
    { id: 1, description: <div className="col-lg-12"> 
                            {/* testimonials */}
                            <div className="testimonials ttm-testimonial-box-view-style2" role="group">
                                <div className="testimonial-top">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="https://via.placeholder.com/150x150?text=150x150+01.jpg" alt="testimonial-img" />
                                        </div>
                                    </div>
                                    <div className="testimonial-caption">
                                        <label>Manager</label>                                             
                                        <h3>Alex Jhon Martin</h3>
                                    </div>
                                </div>
                                <div className="testimonial-content">
                                    <blockquote className="testimonial-text">It has always been so easy to do work with any team member at HireAT. 
                                    An effortless partnership. I was mentored by one of the consultants in a time when I was very young and vulnerable in 
                                    the HR world. Recommend HireAT!</blockquote>
                                </div>
                            </div>{/* testimonials end */}
                        </div> 
    },
    { id: 2, description: <div className="col-lg-12"> 
                            {/* testimonials */}
                            <div className="testimonials ttm-testimonial-box-view-style2" role="group">
                                <div className="testimonial-top">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="https://via.placeholder.com/150x150?text=150x150+02.jpg" alt="testimonial-img" />
                                        </div>
                                    </div>
                                    <div className="testimonial-caption">
                                        <label>Manager</label>                                             
                                        <h3>Alex Jhon Martin</h3>
                                    </div>
                                </div>
                                <div className="testimonial-content">
                                    <blockquote className="testimonial-text">A national award-winning staffing agency that was built with our 
                                    trademarked tagline, Expect More, We Do. was born out of the desire to be better. We developed our 4-dimensional
                                     matching process with our clients and contractors in mind.</blockquote>
                                </div>
                            </div>{/* testimonials end */}
                        </div> 
    }
];


export class Home2 extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
          photoIndex: 0,
          isOpen: false,
          currentSlide: 0,
        };
    }


    handleAfterChange = (index) => {
        console.log("after change", index);
        this.setState({
        currentSlide: index
        });
    };

    render() {
        const { photoIndex, isOpen } = this.state;
        const settings = {
            beforeChange: function (currentSlide, nextSlide) {
              console.log("before change", currentSlide, nextSlide);
            },
            afterChange: this.handleAfterChange
          };


        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToScroll: 1,
            slidesToShow:4,
            rows: 1,
           
            responsive: [{
        
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };
        
        return (       
          <div className="site-main">

            <Header/>

            
            {/* Banner */} 
            <Banner/>
            {/* Banner end */} 

            
            {/* about-section */}
            <section className="ttm-row about-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-6">
                            {/* section title */}
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>who <span className="text-theme-SkinColor">we are!</span></h3>
                                    <h2 className="title">Best Employment Services For Your Business</h2>
                                </div>
                                <div className="title-desc">
                                    <p>Our dedicated group of experts assists you with meeting your business objectives. We give job searchers and
                                     IT experts the chance to track down ideal a profession that guarantees long term development and learning.</p>
                                </div>
                            </div>{/* section title end */}
                            <div className="mb-35">
                                <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-DarkColor">
                                    <li><i className="far fa-check-circle"></i>
                                        <div className="ttm-list-li-content">The most progressive and expert staffing arrangement worldwide</div>
                                    </li>
                                    <li><i className="far fa-check-circle"></i>
                                        <div className="ttm-list-li-content">With over 2,000 offices & thousands of recruiters through overseas</div>
                                    </li>
                                    <li><i className="far fa-check-circle"></i>
                                        <div className="ttm-list-li-content">Create personalized connections through the branded experience</div>
                                    </li>
                                    <li><i className="far fa-check-circle"></i>
                                        <div className="ttm-list-li-content">We have 90% best strategies that get the best suitable candidates</div>
                                    </li>
                                    <li><i className="far fa-check-circle"></i>
                                        <div className="ttm-list-li-content">All your recruitment process outsourcing with extreme truthfulness</div>
                                    </li>
                                    <li><i className="far fa-check-circle"></i>
                                        <div className="ttm-list-li-content">We have 90% best strategies that get the best suitable candidates</div>
                                    </li>
                                </ul>
                            </div>
                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark" 
                            href={process.env.PUBLIC_URL + '/'}>view more</a>
                        </div>
                        <div className="col-lg-6 col-md-9 col-sm-10 col-12 mx-auto">
                            <div className="mr-40 ml-20 pb-60 pt-lg-50">
                                <div className="d-flex justify-content-between" style={{ backgroundImage: 'url(https://themetechmount.com/react/hireco/images/single-img-01.png)' , backgroundSize: 'cover' }}>
                                    <div className="pt-20 pr-20 bg-theme-WhiteColor ml_20 mb_60 mt-200">
                                        <img src="https://themetechmount.com/react/hireco/images/single-img-02.png" className="img-fluid" alt="bgimage" />
                                    </div>
                                    <div className="d-flex align-items-start h-100 mr_30 pt-50">
                                        <div className="ttm-play-icon-btn p-20 bg-theme-SkinColor">
                                            <Video className="flaticon-play-button text-theme-WhiteColor" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
            {/* about-section end */}


            {/* features-section */}
            <section className="ttm-row features-section bg-theme-GreyColor clearfix" >
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section title */}
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h3>What <span className="text-theme-SkinColor">we serve!</span></h3>
                                    <h2 className="title">Recruitment Services</h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>{/* row end */}
                    {/* row */}
                    <div className="row row-equal-height mb_10">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-before-title style3">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon-research"></i>
                                    </div>
                                </div>
                                <div className="featured-title">
                                    <h3>Human Resource</h3>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-desc">
                                        <p>Make up the workforce of an organization, business firm, industry, or economy</p>
                                    </div>
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-before-title style3">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon-recruitment-7"></i>
                                    </div>
                                </div>
                                <div className="featured-title">
                                    <h3>Executive Search</h3>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-desc">
                                        <p>Specializes in recruiting executives and other personnel for their client company </p>
                                    </div>
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-before-title style3">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon-recruiting"></i>
                                    </div>
                                </div>
                                <div className="featured-title">
                                    <h3>Retained search</h3>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-desc">
                                        <p>HireAT tens to accelerate innovation current and future based solutions to support</p>
                                    </div>
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-before-title style3">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon-recruitment-7"></i>
                                    </div>
                                </div>
                                <div className="featured-title">
                                    <h3>Forte Acquisition</h3>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-desc">
                                        <p>To accelerate innovation current and future based solutions to support clients!</p>
                                    </div>
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-before-title style3">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon-recruitment"></i>
                                    </div>
                                </div>
                                <div className="featured-title">
                                    <h3>Manpower Supply</h3>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-desc">
                                        <p>A quality personnel to the fields of expertise that needed for all kind of projects</p>
                                    </div>
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-before-title style3">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon-technical-support"></i>
                                    </div>
                                </div>
                                <div className="featured-title">
                                    <h3>Technical Services</h3>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-desc">
                                        <p>We do supply variety of services including fabrication, design, commissioning.</p>
                                    </div>
                                </div>
                            </div>{/* ttm-box-view-overlay end */}
                        </div>
                    </div>
                    {/* row end */}
                </div>
            </section>
            {/* features-section end */}


            {/* padding_zero-section */}
            <section className="ttm-row padding_zero-section bg-theme-DarkColor bg-layer-equal-height mb-100 mb-lg-0 clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-6">
                            {/* col-img-img-four */}
                            <div className="col-bg-img-four ttm-col-bgimage-yes ttm-bg mt-100 mt-lg-60 mr-30 mr-lg-0 border border-15
                            border-white p-15 h-100">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: 'url(https://themetechmount.com/react/hireco/images/bg-image/col-bgimage-4.jpg)' }}></div>
                                <div className="layer-content"></div>
                                <img src="https://themetechmount.com/react/hireco/images/bg-image/col-bgimage-4.jpg" className="img-fluid col-bg-img-res" alt="bgimage" />
                            </div>{/* col-img-bg-img-four end */}
                        </div>
                        <div className="col-lg-6">
                            <div className="pt-140 pt-lg-50 pb-100 pb-lg-60">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>Happy <span className="text-theme-SkinColor">Clients</span></h3>
                                        <h2 className="title">Listen Our Clients Stories</h2>
                                    </div>
                                </div>{/* section title end */}
                                <div className="position-relative">
                                    <Slider className="slick_slider slick-arrows-style3" 
                                    {...slick_slider} slidesToShow={1} arrows={true} fade={true} {...settings}>
                                        {sliderElements.map((element) => (
                                            <div key={element.id}>
                                                <p>{element.description}</p>
                                            </div>
                                        ))}
                                    </Slider>
                                    <div className="slick_slider_countable">
                                        <span className="currentSlide">{this.state.currentSlide + 1}</span>
                                        <span> / </span>
                                        <span className="totalSlide">{sliderElements.length}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* padding_zero-section end */}

            
           
            

                        
            {/* client-section */}
            <section className="ttm-row client-section bg-theme-GreyColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            {/* section-title */}
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>our <span className="text-theme-SkinColor">clients</span></h3>
                                    <h2 className="title">Partnership With Top Companies</h2>
                                </div>
                            </div>{/* section-title end */}
                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark" 
                             href={process.env.PUBLIC_URL + '/'}>Explore More Companies</a>
                        </div>
                        <div className="col-lg-8">
                            <div className="row g-0 ttm-vertical_sep mt-lg-50">
                                <div className="col-sm-4">
                                    <div className="client-box">
                                        <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/client/bosch.png" alt="image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="client-box">
                                        <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/client/knr.png" alt="image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="client-box">
                                        <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/client/indiaz.png" alt="image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-horizontal_sep width-100"></div>
                            <div className="row g-0 ttm-vertical_sep">
                                <div className="col-sm-4">
                                    <div className="client-box">
                                        <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/client/tvs.png" alt="image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="client-box">
                                        <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/client/srd.png" alt="image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="client-box mb-0">
                                        <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/client/tmi.png" alt="image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* client-section end */}


            {/* fid-section */}
            <section className="ttm-row fid-section bg-img4 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix" style={{ backgroundImage: 'url("https://themetechmount.com/react/hireco/images/bg-image/row-bgimage-4.jpg")' }}>
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor"></div>
                <div className="container">
                    {/* row */}
                    <div className="row ttm-vertical_sep mt_lg-15">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* fid */}
                            <div className="ttm-fid inside ttm-fid-view-lefticon style3">
                                <div className="ttm-fid-icon-wrapper">
                                    <i className="flaticon flaticon-headhunting"></i>
                                </div>
                                <div className="ttm-fid-contents">
                                    <h4><CountUp start={0} end={9000} duration={20} delay={2} /></h4>
                                    <h3 className="ttm-fid-title">Job seeker</h3>
                                </div>
                            </div>{/* fid end */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* fid */}
                            <div className="ttm-fid inside ttm-fid-view-lefticon style3">
                                <div className="ttm-fid-icon-wrapper">
                                    <i className="flaticon flaticon-technical-support"></i>
                                </div>
                                <div className="ttm-fid-contents">
                                    <h4><CountUp start={0} end={500} duration={20} delay={2} /></h4>
                                    <h3 className="ttm-fid-title">Areas of expertise</h3>
                                </div>
                            </div>{/* fid end */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* fid */}
                            <div className="ttm-fid inside ttm-fid-view-lefticon style3">
                                <div className="ttm-fid-icon-wrapper">
                                    <i className="flaticon flaticon-recruitment-4"></i>
                                </div>
                                <div className="ttm-fid-contents">
                                    <h4><CountUp start={0} end={5000} duration={20} delay={2} /></h4>
                                    <h3 className="ttm-fid-title">career hub</h3>
                                </div>
                            </div>{/* fid end */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* fid */}
                            <div className="ttm-fid inside ttm-fid-view-lefticon style3">
                                <div className="ttm-fid-icon-wrapper">
                                    <i className="flaticon flaticon-recruitment-3"></i>
                                </div>
                                <div className="ttm-fid-contents">
                                    <h4><CountUp start={0} end={10000} duration={20} delay={2} /></h4>
                                    <h3 className="ttm-fid-title">Larger Community</h3>
                                </div>
                            </div>{/* fid end */}
                        </div>
                    </div>
                    {/* row end */}
                    <div className="ttm-horizontal_sep width-100 pt-60 mt-60 mt-lg-40"></div>
                    <div className="row align-items-center mb_35 mb-md-0">
                        <div className="col-lg-8 col-md-8">
                            {/* section title */}
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>More than 1,00 <span className="text-theme-SkinColor">happy client</span></h3>
                                    <h2 className="title"><span className="fw-normal">Recruites</span> Let’s To Work Together Ready To Work 
                                    <span className="fw-normal"> With Us.</span></h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="d-flex align-items-center justify-content-md-end">
                                <h6 className="font-weight-bold mb-0 mr-15">Watch Video!</h6>                                
                                <a  className=" d-flex">
                                    <span className="mb-0 p-10 ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-rounded margin_right10 margin_bottom0">
                                        <Video className="fa fa-play" />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            {/* fid-section end */}


            {/* about-section */}
            <section className="ttm-row about-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-8">
                            {/* section title */}
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>how <span className="text-theme-SkinColor"> we do!</span></h3>
                                    <h2 className="title">Recruitment Process</h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                        <div className="col-lg-6 col-md-4">
                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark mb-15"
                             href={process.env.PUBLIC_URL + '/'}>Get Started</a>
                        </div>
                        <div className="col-lg-6">
                            <div className="featuredbox-number pr-30 pr-lg-0 pb-lg-50 pt-md-20">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                            <i className="ttm-num ti-info"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content ttm-bgcolor-grey">
                                        <div className="featured-title">
                                            <h3>We Identify Your Needs</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Create a list of needs before you create a job posting. It may seem easy to identify a hiring need </p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                            <i className="ttm-num ti-info"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content ttm-bgcolor-grey">
                                        <div className="featured-title">
                                            <h3>Create Recruitment Plan</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Save time and energy by creating the best recruitment plan. To get the word out about the job</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                            <i className="ttm-num ti-info"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content ttm-bgcolor-grey">
                                        <div className="featured-title">
                                            <h3>Recruit Top Candidates</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>You will need to maintain timely communication or they will quickly move on to other opportunities</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                            <i className="ttm-num ti-info"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content ttm-bgcolor-grey">
                                        <div className="featured-title">
                                            <h3>The Brand Job Offering</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Create a list of needs before you create a job posting. It may seem easy to identify a hiring need</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10 col-11 m-auto">
                            <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg spacing-2 z-index_1">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                <div className="layer-content">                                      
                                </div>
                            </div>
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper">
                                <img className="img-fluid" src="https://themetechmount.com/react/hireco/images/single-img-03.jpg" alt="single_03" />
                            </div>{/* ttm_single_image-wrapper */}                           
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
            {/* about-section end */}



            <Footer/>
                        
          </div>
        )
    }
}


export default Home2;