import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Contact extends Component {
    render() {
        
        return (
            <div className="site-main">
                <Header />

                {/* PageHeader */} 
                <PageHeader           
                    title="Contact us" 
                    breadcrumb="Contact" 
                />
                {/* PageHeader end */}


               


                {/* map-section */} 
                <section className="ttm-row map-section bg-theme-GreyColor clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title title-style-center_text pb-15">
                                    <div className="title-header">
                                        <h3>get in <span className="text-theme-SkinColor">touch</span></h3>
                                        <h2 className="title">Feel Free To Contact</h2>
                                    </div>
                                </div>{/* section title end */} 
                            </div>
                            <div className="col-lg-6">
                                <iframe height="530" width="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248849.86539739326!2d80.0883449655192!3d13.047525522022576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265ea4f7d3361%3A0x6e61a70b6863d433!2sChennai%2C%20Tamil%20Nadu%2C%20India!5e0!3m2!1sen!2sus!4v1697815761048!5m2!1sen!2sus" />
                            </div>
                            <div className="col-lg-6">
                                <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-WhiteColor z-index-2 p-40 p-lg-30 mt-lg-30">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                        <form id="contact_form" className="contact_form wrap-form">
                                            <div className="row ttm-boxes-spacing-10px">
                                                <div className="col-md-12 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="name" type="text" placeholder="Your Name" required="required" />
                                                    </label>
                                                </div>
                                                <div className="col-md-6 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="email" type="text" placeholder="Your Email" required="required" />
                                                    </label>
                                                </div>
                                                <div className="col-md-6 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="subject" type="text" placeholder="Subject" required="required" />
                                                    </label>
                                                </div>
                                                <div className="col-md-12 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="phone" type="text" placeholder="Phone Number" required="required" />
                                                    </label>
                                                </div>
                                                <div className="col-md-12 ttm-box-col-wrapper">
                                                    <label>
                                                        <textarea name="message" rows="7" placeholder="Message" required="required"></textarea>
                                                    </label>
                                                </div>
                                            </div>
                                            <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                            ttm-btn-color-skincolor w-100" type="submit">send a message!</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </section>
                {/* map-section end */} 

                {/* action-section */}
                <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-md-flex align-items-center justify-content-between">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box icon-align-before-content style2">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                                <i className="flaticon flaticon-recruitment-5"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Incredible Recruitment & Staffing Agency</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p> We have over 10 years experience in job consultancy.</p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end */}
                                    <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                    href={process.env.PUBLIC_URL + '/contact'}>Hiring Now!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* action-section end */}

                <Footer/>

            </div>
        )
    }
}


export default Contact;